@font-face {
  font-family: "iconfont"; /* Project id 4085816 */
  src: url('iconfont.woff2?t=1688710766651') format('woff2'),
       url('iconfont.woff?t=1688710766651') format('woff'),
       url('iconfont.ttf?t=1688710766651') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chakanxiangqing:before {
  content: "\e60a";
}

.icon-shuaxin1:before {
  content: "\e60b";
}

.icon-dingwei:before {
  content: "\e60c";
}

.icon-jujiaojiedian:before {
  content: "\e60d";
}

.icon-jiantou:before {
  content: "\e609";
}

.icon-tiaozhuan:before {
  content: "\e608";
}

.icon-close:before {
  content: "\e607";
}

.icon-loading:before {
  content: "\e600";
}

.icon-tishi:before {
  content: "\e601";
}

.icon-shuaxin:before {
  content: "\e602";
}

.icon-carbon_new-tab:before {
  content: "\e603";
}

.icon-copy:before {
  content: "\e604";
}

.icon-fankui:before {
  content: "\e605";
}

.icon-upload:before {
  content: "\e606";
}

