@keyframes myRotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
.content {
  text-align: center;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;

  .title {
    font-family: PingFang SC;
    font-size: 40px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: 0em;
    text-align: center;
    background: linear-gradient(180deg, #f6f6f7 0%, #7e808f 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin-top: 80px;
    margin-bottom: 40px;
  }

  .filterPart {
    position: relative;
    background-color: #fff;
    height: 206px;
    width: 100%;
    margin-bottom: 30px;
    padding: 30px;
    display: inline-block;
    box-sizing: border-box;
    .field {
      text-align: left;
      margin-bottom: 20px;
      .label {
        color: #7e808f;

        /* 14/CN-Regular */
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        margin-right: 40px;
        width: 70px;
        display: inline-block;
      }
      .input {
        width: 240px;
        border-radius: 2px;
        background: var(--fill-2, #f2f3f5);
      }
      .paste {
        display: inline-flex;
        padding: 5px 16px;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 2px;
        border: 1px dashed var(--line-2, #e5e6eb);
        background: var(--fill-2, #f2f3f5);
        margin-left: 12px;
      }
    }

    .btnPart {
      margin-top: 40px;
      text-align: left;
      .btnActive {
        color: #fff;
        font-family: PingFang SC;
        font-size: 14px;
        margin-right: 12px;
        font-style: normal;
        padding: 5px 16px;
        cursor: pointer;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        border-radius: 2px;
        background: linear-gradient(204deg, #6d0cfe 0%, #b235ff 100%);
      }
      .btn {
        color: var(--text-4, #4e5969);
        cursor: pointer;

        /* 14/CN-Regular */
        padding: 5px 16px;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        border-radius: 2px;
        background: var(--fill-2, #f2f3f5);
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
    }
  }

  .filterPartLoading {
    &::before {
      content: "";
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      display: inline-block;
      background: rgba(255, 255, 255, 0.3);
      z-index: 9;
    }
  }

  .loadingCt {
    text-align: center;
    color: #626e8a;
    font-family: PingFang SC;
    margin-top: 30px;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    .loading {
      margin-right: 10px;
      animation: myRotate 1.6s linear infinite;
      vertical-align: middle;
    }
  }

  .resPart {
    background-color: #fff;
    min-height: 206px;
    width: 100%;
    display: inline-block;
    padding: 30px;
    box-sizing: border-box;
    text-align: left;
    .idString {
      color: #7e808f;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 137.5% */
      margin-bottom: 20px;
    }
    .preCode {
      text-wrap: balance;
    }

    .toptitle {
      color: var(--text-5, #1d2129);
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
      .copyBtn {
        margin-left: 16px;
        vertical-align: middle;
      }
    }
    .bottomResNormal {
      background: transparent !important;
      padding: 0px !important;
    }
    .bottomRes {
      padding: 8px 12px;
      color: var(--text-5, #1d2129);
      /* 14/CN-Regular */
      font-family: PingFang SC;
      font-size: 14px;
      margin-top: 8px;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 20px;
      line-height: 22px; /* 157.143% */
      border-radius: 2px;
      background: var(--fill-2, #f2f3f5);
      position: relative;
      .count {
        position: absolute;
        right: 10px;
        bottom: 6px;
        color: var(--text-3, #86909c);
        text-align: right;

        /* 12/CN-Regular */
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
      }
    }
  }
}
