@import "./iconfont/iconfont.css";
/* reset.css */

html,
body {
  margin: 0;
  font-family: PingFang SC;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: url("image/bg.png") no-repeat;
  background-size: cover;
}
textarea {
  font-family: PingFang SC;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin: 0px;
}
.text-two-ellipsis {
  overflow: hidden;

  text-overflow: ellipsis;

  display: -webkit-box;

  -webkit-line-clamp: 2;

  /*! autoprefixer: off */

  -webkit-box-orient: vertical;
}

/* 滚动槽 */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  border-radius: 3px;
  background: transparent;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.08);
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: #1d4e95;
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ant-pagination-item {
  /* background: #3c4a6d !important;
  border: 1px solid #3872ca !important; */
  border-radius: 2px !important;
  height: 32px !important;
  font-weight: 400;
  font-size: 14px !important;
  /* identical to box height, or 140% */
}
.ant-pagination-item a {
  color: #394a70 !important;

  line-height: 32px !important;
}
.ant-pagination .ant-pagination-item-active {
  background: #326ffd !important;
  /* border: 1px solid #3872ca !important; */
}

.ant-pagination .ant-pagination-item-active a {
  color: #fff !important;
}

.ant-pagination-item-ellipsis {
  color: #394a70 !important;
}
.ant-pagination-prev,
.ant-pagination-next {
  line-height: 32px !important;
  height: 32px !important;
}
.ant-pagination-prev .ant-pagination-item-link {
  /* background: #3c4a6d !important;
  background-color: #3c4a6d !important; */
  color: #394a70 !important;
  border: 1px solid #3872ca !important;
  border-radius: 2px !important;
  line-height: 32px !important;
}

.ant-pagination-next .ant-pagination-item-link {
  /* background: #3c4a6d !important;
  background-color: #3c4a6d !important; */
  color: #394a70 !important;
  /* border: 1px solid #3872ca !important; */
  line-height: 32px !important;
  border-radius: 2px !important;
}

.ant-pagination-options .ant-select-selector {
  /* background: #3c4a6d !important;
  border: 1px solid #3872ca !important; */
  border-radius: 2px !important;
  height: 32px !important;
  font-weight: 400;
  font-size: 14px !important;
  /* identical to box height, or 140% */

  color: #394a70 !important;

  line-height: 32px !important;
}
.ant-pagination-total-text {
  color: #394a70 !important;
  float: left;
}
.ant-pagination-options-quick-jumper {
  color: #394a70 !important;
}
.ant-pagination-options-quick-jumper input {
  background: #3c4a6d !important;
  color: #394a70 !important;
  border-radius: 2px !important;
  border: 1px solid #3872ca !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #b235ff !important;
  background-color: #b235ff !important;
}
